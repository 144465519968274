import * as React from "react";
import { Typography, Box, Stack, Grid, Button, darken } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StaticImage } from "gatsby-plugin-image";

// utils
import Link from "../utils/link";
import Section from "../utils/section";
import theme from "../utils/theme";

const WhiteButton = styled(Button)(({ theme }) => ({
    border: "none",
    color: theme.palette.primary.main,
    backgroundColor: "#fff",
    "&:hover": {
        border: "none",
        backgroundColor: theme.palette.grey[200],
    },
}));

const FastOrder = () => {
    return (
        <Section>
            <Box
                position="relative"
                borderRadius={3}
                p={4}
                style={{
                    background: `linear-gradient(to left, ${darken(theme.palette.primary.main, 0.1)} 0%, ${
                        theme.palette.primary.main
                    } 100%)`,
                    color: "#fff",
                }}
            >
                <Box
                    width={140}
                    position="absolute"
                    sx={{
                        width: [120, 140],
                        right: [0, 30],
                        top: [-30, -50],
                    }}
                >
                    <StaticImage
                        imgStyle={{ borderRadius: 16 }}
                        src="../images/ordine-veloce.png"
                        alt="Ordine veloce"
                        layout="fullWidth"
                    />
                </Box>

                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <Typography variant="h3" component="h3">
                                    <strong>Ordine veloce</strong>
                                </Typography>
                                <Typography>
                                    <strong>Vuoi ridurre i tempi di attesa per avere i tuoi medicinali?</strong>
                                    <br />
                                    Ordina i tuoi prodotti su Whatsapp e noi ti faremo trovare l'ordine pronto in
                                    farmacia.
                                </Typography>
                                <Box pt={2}>
                                    <Link
                                        type="internal"
                                        href="/servizi/ordine-veloce"
                                        underline="none"
                                        title="Scopri di più sull'ordine veloce"
                                    >
                                        <WhiteButton size="large">Scopri di più</WhiteButton>
                                    </Link>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Section>
    );
};

export default FastOrder;

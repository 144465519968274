import * as React from "react";
import { Typography, Box, Stack, Grid, Button } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// utils
import Link from "../utils/link";
import theme from "../utils/theme";
import Section from "../utils/section";

const Covid = () => {
    return (
        <Section>
            <Box position="relative" borderRadius={3} p={4} style={{ background: theme.palette.grey[100] }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <Typography variant="h3" component="h3">
                                <strong>Tampone COVID-19</strong>
                            </Typography>
                            <Typography>Nella nostra Farmacia puoi eseguire il test antigienico rapido per il COVID-19 su prenotazione.</Typography>
                            <Box pt={2}>
                                <Link type="internal" href="/servizi/tampone-covid-19" underline="none" title="Scopri di più sul tampone rapido Covid-19">
                                    <Button variant="contained" size="large">
                                        Scopri di più
                                    </Button>
                                </Link>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box mb={-4}>
                            <StaticImage src="../images/tampone-covid-19.png" alt="Tampone COVID-19" layout="fullWidth" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Section>
    );
};

export default Covid;

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Stack, Typography } from "@mui/material";

// components
import Page from "../utils/page";
import FastOrder from "../components/fast-order";
import Covid from "../components/covid";
import AreeServiziCard from "../components/aree-e-servizi";
import Hero from "../utils/hero";
import Section from "../utils/section";
import theme from "../utils/theme";

// markup
const HomePage = () => {
    return (
        <Page
            title="La nostra esperienza, la tua salute."
            description="Approfitta della consulenza di un farmacista specializzato. La nostra mission è mettere al centro i tuoi bisogni, seguendoti nell'intero percorso di cura."
        >
            <Hero
                color="#fff"
                title={
                    <>
                        La nostra esperienza, <br /> la tua salute.{" "}
                    </>
                }
                description="L'obiettivo è prendersi cura del paziente nel suo complesso, ovvero nella totalità della persona, e non solo della patologia, con una sensibilità particolare rivolta alle terapie naturali e omeopatiche."
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../images/farmacia-settimo-miglio-cosmesi.jpg"
                        alt="Farmacia Settimo Miglio"
                        layout="fullWidth"
                    />
                }
                image={
                    <StaticImage
                        imgStyle={{ borderRadius: 16 }}
                        src="../images/farmacia-settimo-miglio-team.jpg"
                        alt="Farmacia Settimo Miglio team"
                        layout="fullWidth"
                    />
                }
            />

            <Section>
                <Stack spacing={2}>
                    <Typography variant="h3" component="h2" color="primary">
                        Farmacia Settimo Miglio
                    </Typography>
                    <Typography>
                        La nostra mission è mettere al centro i tuoi bisogni, seguendoti nell'intero percorso di cura
                        con supporto, prodotti e consulenza di alto livello.
                        <br />
                        La specializzazione dei nostri farmacisti in materia fitoterapica, omeopatica e floriterapica
                        (fiori di Bach) offre maggiori opputunità per la tua salute.
                    </Typography>
                </Stack>
            </Section>

            <FastOrder />

            <Covid />

            <AreeServiziCard />
        </Page>
    );
};

export default HomePage;
